import { render, staticRenderFns } from "./TeamFour.vue?vue&type=template&id=4e8cbd56&"
import script from "./TeamFour.vue?vue&type=script&lang=js&"
export * from "./TeamFour.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports